<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
		<div class="w-100 h-auto flex flex-column">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
				<el-form-item label="问卷名称" prop="title">
				  <el-input v-model="form.title" placeholder="请输入问卷名称" type="text" clearable />
				</el-form-item>
				<el-form-item label="时间期限" prop="time">
				  <el-date-picker
					 v-model="time"
					 @change="changeTime"
					 value-format="yyyy-MM-dd"
					 type="daterange"
					 range-separator="至"
					 start-placeholder="开始日期"
					 end-placeholder="结束日期">
				  </el-date-picker>
				</el-form-item>
				<el-form-item label="问卷模版">
				  <el-select v-model="form.template_id" placeholder="请选择问卷模版" style="width: 250px;margin-right: 10px;" @change="templateChange">
				    <el-option :value="val.id" :label="val.name" v-for="(val,key) in templates" :key="key"/>
				  </el-select>
				</el-form-item>
				<el-form-item label="问卷介绍">
				   <el-input v-model="form.brief" placeholder="请输入问卷介绍" size="small" type="textarea" :rows="3"></el-input>
				</el-form-item>
				<el-form-item  label="状态">
				   <el-radio-group v-model="form.status">
					<el-radio :label="1">使用</el-radio>
					<el-radio :label="2">停用</el-radio>
				   </el-radio-group>
				 </el-form-item>
				<el-form-item label="排序" prop="sort">
				  <el-input v-model="form.sort" placeholder="请输入排序" type="text" clearable />
				</el-form-item>
			</el-form>
			<div class="w-100 flex-all mt-2">
			  <el-button size="medium" @click="cancel">取 消</el-button>
			  <el-button type="primary" size="medium" @click="handleSubmit" style="margin-left: 30px;">确 定</el-button>
			</div>
		</div>
		</el-card>
	</div>
</template>

<script>
import GlobalTips from "@/components/GlobalTips";
import common from "@/common/mixins/common.js"
import Breadcrumb from '@/components/Breadcrumb';
import tinymce from "@/components/common/tinymce.vue"
export default {
	inject:['app'],
	mixins:[common],
	components: {
		GlobalTips,
		Breadcrumb,
		tinymce
	},
	data() {
		return {
			preUrl: 'wj',
			autoRequest: false,
			queryForm: {
				page: 1,
				limit: 10,
				keyword: '',
			},
			form:{
				title:'',
				time:[],
				template_id:'',
				brief:'',
				sort:100,
				status:1,
			},
			rules:{
				title: [
				  { required: true, message: '请输入活动名称', trigger: 'blur' }
				],
			},
			templates:[],
			time:[],
		}
	},
	mounted() {
		if(this.$route.query.info){
			 this.form = this.$route.query.info
			 if(this.$route.query.info.stime != '' && this.$route.query.info.etime != ''){
				 this.form.time = []
				 this.form.time[0] = this.$route.query.info.stime
				 this.form.time[1] = this.$route.query.info.etime
				 this.time = []
				 this.time[0] = this.$route.query.info.stime
				 this.time[1] = this.$route.query.info.etime
				 this.$forceUpdate();
			 }
		}
		this.getTemplates()
	},
	methods:{
		changeTime(e){
			this.form.time = []
			this.form.time = e
		},
		templateChange(e){
			this.form.template_id = e
		},
		getTemplates(){
			this.axios.get('/manage/template/all').then(res=>{
				if(res.status){
					this.templates = res.data
				}
			})
		},
		reset() {
		  this.form = {
			 title:'',
			 time:[],
			 template_id:'',
			 brief:'',
			 sort:100,
			 status:1,
		  }
		  this.resetForm('form')
		},
		handleSubmit() {
		  this.$refs.form.validate(valid => {
		    if (valid) {
		      if (this.form.sort) {
		        this.form.sort = parseInt(this.form.sort)
		      }
		      if (this.form.id === undefined) {
		        this.axios.post('/manage/'+this.preUrl + '/add', this.form).then(res => {
		          if (res.status) {
		            this.$message.success('编辑成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      } else {
		        this.axios.put('/manage/'+this.preUrl + '/edit/' + this.form.id, this.form).then(res => {
		          if (res.status) {
		            this.$message.success('修改成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      }
		    }
		  })
		},
		cancel(){
			this.$router.go(-1);
		},
	},
}
</script>

<style>
</style>